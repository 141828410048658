<template>
  <div>
    <b-modal
      id="modal-edit-order"
      ref="modal"
      header-class="headerModal"
      shadow
      :title="$t('Edit') + ' ' + orderNumber"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #242f6e;">{{ `${$t('Edit')} ${orderNumber}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #242f6e;height: 60px;"
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <b-tabs
        v-model="tabIndex"
        style="padding: 10px;"
      >
        <b-tab style="padding: 5px;">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Order") }}</strong>
          </template>
          <b-form>
            <!-- <b-form-group
          id="input-group-1"
          :label="`${$t('Order Type')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="getOrder.orderType"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group> -->
            <b-form-group
              id="input-group-1"
              :label="`${$t('OrderT')}:`"
              label-for="input-1"
            >

              <vue-select
                v-model="getOrder.orderType"
                :options="getOrderTypes"
                label="text"
                :reduce="(e) => e.value"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <b-form-checkbox
              id="checkbox-1"
              v-model="getOrder.pickUpInStore"
              style="margin-top: 20px;"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('PickUpInStore') }}
            </b-form-checkbox>
          </b-form>

          <pre />

          <b-form-group
            id="input-group-1"
            :label="`${$t('Description')}:`"
            label-for="input-1"
          >
            <b-form-textarea
              id="input-1"
              v-model="getOrder.description"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.pickUpInStore"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group> -->

          <PRE />
        </b-tab>
        <b-tab style="padding: 5px;">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Files") }}</strong>
          </template>
          <div
            v-if="getimagesOrder.length > 0"
          >
            <div
              v-for="(image, i) in getimagesOrder"
              :key="i"
            >
              <div
                v-if="image.fileType ==='Scetch'"
                class="files"
                style="display: flex;align-items: center; gap: 30px;"
              >
                <span style="color: #262E6C; font-weight: bold; width: 100px;">{{ $t("Sketchs") }}</span>
                <div>
                  <img
                    v-b-tooltip.hover
                    :title="$t('ClickToViewSketchPhoto') +' '+ image.fileName.replace(/^\d+-/, '')"
                    class="image"
                    style="width: 100px;"
                    :src="image.url"
                    alt="Not an image"
                    @click="index = i"
                  >
                  <b-icon-x-circle
                    :key="i"
                    style="margin-left: 25px; margin-right: 5px;"
                    @click="handleDeleteClick(image.fileDataId)"
                  />
                  <button
                    style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                    @click="downloadFile(image.url)"
                  >
                    <b-icon-download style="color: white;" />
                  </button>
                </div>
              </div>
              <div
                v-if="image.fileType ==='Contract'"
                class="files"
                style="display: flex;align-items: center; gap: 30px; padding-top: 30px;"
              >
                <span style="color: #262E6C; font-weight: bold;width: 100px;">{{ $t("Contracts") }}</span>
                <div>
                  <img
                    v-b-tooltip.hover
                    style="width: 100px;"
                    :title="$t('ClickToViewSketchPhoto') +' '+ image.fileName.replace(/^\d+-/, '')"
                    class="image"
                    alt="Not an image"
                    :src="image.url"
                    @click="index = i"
                  >
                  <b-icon-x-circle
                    :key="i"
                    style="margin-left: 25px; margin-right: 5px;"
                    @click="handleDeleteClick(image.fileDataId)"
                  />
                  <button
                    style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                    @click="downloadFile(image.url)"
                  >
                    <b-icon-download style="color: white;" />
                  </button>
                </div>
              </div>
              <div
                v-if="image.fileType ==='OtherDocument'"
                class="files"
                style="display: flex;align-items: center; gap: 30px; padding-top: 30px;"
              >
                <span style="color: #262E6C; font-weight: bold;">{{ $t("OtherDocuments") }}</span>
                <div>
                  <img
                    v-b-tooltip.hover
                    style="width: 100px;"
                    :title="$t('ClickToViewSketchPhoto') +' '+ image.fileName.replace(/^\d+-/, '')"
                    class="image"
                    alt="Not an image"
                    :src="image.url"
                    @click="index = i"
                  >
                  <b-icon-x-circle
                    :key="i"
                    style="margin-left: 25px; margin-right: 5px;"
                    @click="handleDeleteClick(image.fileDataId)"
                  />
                  <button
                    style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                    @click="downloadFile(image.url)"
                  >
                    <b-icon-download style="color: white;" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            style="padding: 10px; background-color: gainsboro;text-align: center; font-weight: 500;"
          >
            {{ $t('NoSketchPhoto') }}
          </div>
          <vuegalleryslideshow
            :images="getimagesOrder"
            :index="index"
            @close="index = null"
          />
          <div style="display: flex;flex-direction: column;">
            <div style="display: flex;">
              <AddFile
                ref="addFile1"
                :folder="'OrderPhotos'"
                :label-text="$t('UploadSketch')"
                :file-data-type="'Scetch'"
                :file-name="createFileDataRequestDtos"
              />
              <AddFile
                ref="addFile2"
                :folder="'OrderPhotos'"
                :label-text="$t('UploadContract')"
                :file-data-type="'Contract'"
                :file-name="createFileDataRequestDtos"
              />
            </div>
            <AddFile
              ref="addFile3"
              :folder="'OrderPhotos'"
              :label-text="$t('OtherDocuments')"
              :file-data-type="'OtherDocument'"
              :file-name="createFileDataRequestDtos"
            />
          </div>
        </b-tab>
        <div class="buttonsEverywhere">
          <b-button
            v-if="tabIndex == 1"
            type="button"
            variant="none"
            class="buttonSubmit"
            :disabled="loading"
            @click="onSubmit"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}
          </b-button>
          <b-button
            v-if="tabIndex == 0"
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="NextPage"
          >
            {{ $t('Next') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 0px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import vuegalleryslideshow from 'vue-gallery-slideshow';
import { mapGetters, mapActions } from 'vuex'
import AddFile from '@/modals/AddFile.vue'
import AWS from 'aws-sdk';
import { AWSurl } from '../../../domainConfig';

// import {
//   required, minLength,
// } from 'vuelidate/lib/validators';

export default {
  components: {
    vuegalleryslideshow,
    AddFile,
    // flatPickr,
    // DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderNumber'],

  data() {
    return {
      loading: false,
      tabIndex: 0,
      bucketName: AWSurl.bucketName,
      index: null,
      createFileDataRequestDtos: [],
      form: {
        pickUpInStore: false,
        pickUpDate: null,
        description: null,
        orderType: null,
      },
      getOrderTypes: [
        { value: 'InStore', text: this.$t('InStore') },
        { value: 'Online', text: 'Online' },
        { value: 'WholeSale', text: this.$t('WholeSale') },
        { value: 'Instagram', text: 'Instagram' },
        { value: 'Whatsapp', text: 'Whatsapp' },
        { value: 'Viber', text: 'Viber' },
        { value: 'Facebook', text: 'Facebook' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getLanguage', 'getOrder', 'getimagesOrder']),
    orderByAdmin() {
      return this.$store.getters.getOrder
    },
  },
  watch: {
    orderByAdmin: {
      handler(newVal) {
        this.form = {
          description: newVal.description,
          orderType: newVal.orderType,
          pickUpInStore: newVal.pickUpInStore,
        }
      },
    },
  },
  // validations: {
  //   form: {
  //     name: {
  //       required,
  //       minLength: minLength(3),
  //       // alpha,
  //     },
  //   },
  // },
  mounted() {
    // console.log('df', this.getOrder.description)
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  methods: {
    ...mapActions(['deleteFile', 'getFileByOrderNumber']),
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleDeleteClick(test) {
      console.log('valueee', test)
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'none',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deletePhotoo(test);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async deletePhotoo(value) {
      console.log('delete', value)
      await this.deleteFile({
        fileDataId: value,
        successCallback: () => {
          this.getFileByOrderNumber(this.orderNumber)
        },
      })
    },
    async onSubmit() {
      try {
        this.loading = true;

        await this.$refs.addFile1.uploadFile();
        await this.$refs.addFile2.uploadFile();
        await this.$refs.addFile3.uploadFile();
        const payload = {
          ...this.getOrder,
          createFileDataRequestDtos: this.createFileDataRequestDtos,
        }
        this.$emit('editOrderrr', payload)
        this.loading = false;
        this.$refs.modal.hide()
        this.resetForm()
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.createFileDataRequestDtos = [];
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    NextPage() {
      this.tabIndex = 1
    },
    resetForm() {
      this.form.name = ''
      this.createFileDataRequestDtos = []
      this.fileData = [];
    },
  },
}
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}
.files{
  display: flex;
                            flex-direction: row;
                            margin-bottom: 10px;
                            background-color: rgb(207, 207, 207);
                            border: none;
                            box-shadow: none;
                            padding: 12px;
                            color: #212529;
                            border-radius: 8px;
                            width: 100%;
                            margin-right: 10px;
                            justify-content: space-between;
                            align-items: center;


}
</style>
